import customersIcon from "src/assets/images/totalCustomers.png";
import { CustomDataTable, CustomPopup } from "src/components";
import { renderCurrency, renderNumber } from "src/assets/functions";
import { useQueries } from "@tanstack/react-query";
import { getCustomerOrderMetrics, getTopCustomerOrderMetrics } from "src/api";
import { DownIcon } from "src/assets/svg";
import { useState } from "react";

const CustomerOrder = () => {
	const [orderBy, setOrderBy] = useState("");

	const topCustomerColumns = [
		{
			name: "Customer Name",
			selector: (row) => `${row.full_name} (${renderNumber(row.customer_total_orders)})`,
			grow: 2,
		},
		{
			name: "Total bill",
			selector: (row) => (row.total_order_bill ? renderCurrency(row.total_order_bill) : "Not Available"),
		},
		{
			name: "Amount paid",
			selector: (row) => (row.total_order_amount ? renderCurrency(row.total_order_amount) : "Not Available"),
		},
		{
			name: "Revenue",
			selector: (row) => (row.total_revenue ? renderCurrency(row.total_revenue) : "Not Available"),
		},
	];

	const [{ data: rawDetails }, { data: rawTopCustomers, isLoading: isTopCustomersLoading }] = useQueries({
		queries: [
			{
				queryKey: ["getCustomerOrderMetrics"],
				queryFn: getCustomerOrderMetrics,
				suspense: true,
			},
			{
				queryKey: ["getTopCustomerOrderMetrics", orderBy],
				queryFn: () => getTopCustomerOrderMetrics({ orderBy }),
			},
		],
	});

	const details = rawDetails?.data;
	const topCustomers = rawTopCustomers?.data;

	return (
		<div className="px-[2.5rem]">
			<div className="flex justify-between">
				<h2 className="my-[30px] text-black text-[2rem] font-bold">Customer Order</h2>
			</div>
			<div className="grid grid-cols-2 mb-8 border border-gray-300 divide-x divide-gray-300 rounded-lg">
				<div className="px-4 py-5">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Total Customers</p>
						{<img src={customersIcon} alt="" />}
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(details?.totalCustomers?.total_customers)}</p>
				</div>
				<div className="px-4 py-5">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">New Customers (this month)</p>
						{<img src={customersIcon} alt="" />}
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(details?.newCustomers?.newCustomersCount)}</p>
				</div>
			</div>
			<div className="mt-[50px]">
				<div className="flex items-center justify-between mb-[30px]">
					<p className="text-[2rem] font-bold">Top Customers</p>
					<CustomPopup
						trigger={
							<button className="flex items-center gap-2 ml-auto btn-primary">
								Order By <DownIcon className="text-white" />
							</button>
						}
						arrow={false}
						closeOnDocumentClick
						position={["bottom left"]}
						width={"fit-content"}
						nested
						childrenArray={[
							{
								text: "Order count",
								onClick: () => setOrderBy("customer_total_orders"),
							},
							{
								text: "Order bill",
								onClick: () => setOrderBy("total_order_bill"),
							},
							{
								text: "Paid amount",
								onClick: () => setOrderBy("total_order_amount"),
							},
							{
								text: "Revenue",
								onClick: () => setOrderBy("total_revenue"),
							},
						]}
					/>
				</div>
				<CustomDataTable progressPending={isTopCustomersLoading} columns={topCustomerColumns} data={topCustomers} pagination={false} />
			</div>
		</div>
	);
};
export default CustomerOrder;
