import { useQueries } from "@tanstack/react-query";
import { getAllLocation, getDueOrders } from "../../../api";
import { renderCurrency } from "../../../assets/functions";
import { CustomDataTable, FilterByDate, FilterDateTrigger } from "src/components";
import { format, parse, parseISO, subDays } from "date-fns";
import { useSearchParams } from "react-router-dom";
import Popup from "reactjs-popup";

const Overview = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const startDate = searchParams.get("startDate") ?? format(subDays(new Date(), 1), "yyyy-MM-dd");
	const endDate = searchParams.get("endDate") ?? format(new Date(), "yyyy-MM-dd");
	const location = searchParams.get("location");
	const page = searchParams.get("page") ?? 1;
	const per_page = searchParams.get("per_page") ?? 50;

	const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
	const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());

	const formattedStartDate = format(parsedStartDate, "dd MMM yyyy");
	const formattedEndDate = format(parsedEndDate, "dd MMM yyyy");

	const [{ data: dueOrders, isFetching }, { data: locations }] = useQueries({
		queries: [
			{
				queryKey: ["getDueOrders", startDate, endDate, location, page, per_page],
				queryFn: () => getDueOrders({ startDate, endDate, location, page, per_page }),
				suspense: true,
			},
			{
				queryKey: ["getAllLocation"],
				queryFn: getAllLocation,
				suspense: true,
			},
		],
	});

	const columns = [
		{
			name: "Order ID",
			selector: (row) => row?.serial_number,
			width: "120px",
			compact: true,
		},
		{
			name: "Customer",
			selector: (row) => row?.full_name,
			grow: 2,
		},
		{
			name: "Placed",
			selector: (row) => format(parseISO(row?.created_at), "dd MMM yyyy"),
			compact: true,
		},
		{
			name: "Ready By",
			selector: (row) => format(parseISO(row?.dateTimeOut), "dd MMM yyyy"),
		},
		{
			name: "Amount",
			selector: (row) => renderCurrency(row?.paidAmount),
		},
	];

	const data = dueOrders?.data?.data;
	const total = dueOrders?.data?.total;

	return (
		<div className="px-[2.5rem] mt-[50px] grid gap-10">
			<div className="flex items-center gap-4 ml-auto">
				<select
					className="px-3 py-4 capitalize rounded-md"
					onChange={(event) => {
						searchParams.set("location", event.target.value);
						setSearchParams(searchParams);
					}}
					value={location}
				>
					<option value="">All Stores</option>
					{locations?.data?.map((location) => (
						<option value={location.id} key={location.id}>
							{location?.locationName}
						</option>
					))}
				</select>
				<Popup
					trigger={<FilterDateTrigger name={searchParams.has("startDate") ? `${formattedStartDate} - ${formattedEndDate}` : "Filter"} />}
					arrow={false}
					closeOnDocumentClick
					className="date"
					position={["bottom left"]}
					contentStyle={{
						width: "fit-content",
						padding: 0,
					}}
				>
					<FilterByDate />
				</Popup>
			</div>
			<CustomDataTable columns={columns} data={data ?? []} progressPending={isFetching} paginationTotalRows={total} />
		</div>
	);
};

export default Overview;
