import React from "react";
import unpaidIcon from "src/assets/images/unpaid1.png";
import { getAllLocation, getUnpaidMetrics } from "src/api";
import { renderCurrency } from "src/assets/functions";
import { CustomDataTable, FilterByDate, FilterDateTrigger } from "src/components";
import { useSearchParams } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";
import { format, parse, parseISO } from "date-fns";
import Popup from "reactjs-popup";

const Unpaid = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const location = searchParams.get("location");

	const page = searchParams.get("page") ?? 1;
	const per_page = searchParams.get("per_page") ?? 50;
	const startDate = searchParams.get("startDate") ?? "2023-01-01";
	const endDate = searchParams.get("endDate") ?? "3000-12-31";

	const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
	const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());
	const formattedStartDate = format(parsedStartDate, "dd MMM yyyy");
	const formattedEndDate = format(parsedEndDate, "dd MMM yyyy");

	const columns = [
		{
			name: "Name",
			selector: (row) => row?.full_name,
		},
		{
			name: "Order",
			selector: (row) => row?.serial_number,
		},
		{
			name: "Placed",
			selector: (row) => format(parseISO(row?.created_at), "dd MMM yyyy"),
			compact: true,
		},
		{
			name: "Ready By",
			selector: (row) => (row?.dateTimeOut ? format(new Date(row?.dateTimeOut), "dd MMM yyyy") : "N/A"),
			compact: true,
		},
		{
			name: "Completed By",
			selector: (row) => (row?.dateInStore ? format(new Date(row?.dateInStore), "dd MMM yyyy") : "N/A"),
			compact: true,
		},
		{
			name: "Collected on",
			selector: (row) => (row?.dateCollected ? format(new Date(row?.dateCollected), "dd MMM yyyy") : "N/A"),
			compact: true,
		},
		{
			name: "Amount left",
			selector: (row) => renderCurrency(row?.bill - row?.paidAmount),
		},
	];

	const [{ data }, { data: locations }] = useQueries({
		queries: [
			{
				queryKey: ["getUnpaidMetrics", location, startDate, endDate, page, per_page],
				queryFn: () => getUnpaidMetrics({ location, startDate, endDate, page, per_page }),
				suspense: true,
			},
			{
				queryKey: ["getAllLocation"],
				queryFn: getAllLocation,
				suspense: true,
			},
		],
	});

	const unpaidDataNumber = data?.data?.unPaidNumbers;

	const unpaidDataOrder = data?.data?.unpaidOrders;

	return (
		<div className="px-[2.5rem]">
			<div className="flex justify-between my-[30px]">
				<h2 className="text-black text-[2rem] font-bold">Unpaid</h2>
				<div className="flex items-center gap-3">
					<select
						className="px-3 py-4 capitalize rounded-md"
						onChange={(event) => {
							searchParams.set("location", event.target.value);
							setSearchParams(searchParams);
						}}
						value={location}
					>
						<option value="">All Stores</option>
						{locations?.data?.map((location) => (
							<option value={location.id} key={location.id}>
								{location?.locationName}
							</option>
						))}
					</select>
					<Popup
						trigger={
							<FilterDateTrigger name={searchParams.has("startDate") ? `${formattedStartDate} - ${formattedEndDate}` : "Filter"} />
						}
						arrow={false}
						closeOnDocumentClick
						className="date"
						position={["bottom left"]}
						contentStyle={{
							width: "fit-content",
							padding: 0,
						}}
					>
						<FilterByDate />
					</Popup>
				</div>
			</div>
			<div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] mb-8 border border-gray-300 divide-x divide-gray-300 rounded-lg">
				<div className="px-4 py-5">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Unpaid</p>
						<img src={unpaidIcon} alt="" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(unpaidDataNumber?.total)}</p>
				</div>
			</div>
			<h2 className="mb-[20px] mt-[50px] text-[#49515C] font-[700]">Unpaid Orders</h2>
			<CustomDataTable columns={columns} data={unpaidDataOrder?.data} paginationTotalRows={unpaidDataOrder?.total} />
		</div>
	);
};
export default Unpaid;
