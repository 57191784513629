import { CustomDataTable, FilterByDate, FilterDateTrigger, Modal } from "src/components";
import { renderNumber } from "src/assets/functions";
import { exportRecentCustomerMetrics, getRecentCustomerMetrics } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { UserIcon } from "src/assets/svg";
import { useState } from "react";
import { CustomerDetails } from "src/modals/customers";
import Popup from "reactjs-popup";
import { useSearchParams } from "react-router-dom";

const NewCustomer = () => {
	const [searchParams] = useSearchParams();

	const page = searchParams.get("page") ?? 1;
	const per_page = searchParams.get("per_page") ?? 50;
	const startDate = searchParams.get("startDate");
	const endDate = searchParams.get("endDate");

	const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
	const [selectedCustomerDetails, setSelectedCustomerDetails] = useState(null);

	const { data } = useQuery({
		queryKey: ["getRecentCustomerMetrics", page, per_page, startDate, endDate],
		queryFn: () => getRecentCustomerMetrics({ page, per_page, startDate, endDate }),
		suspense: true,
	});

	const columns = [
		{
			name: "S/N",
			selector: (_, index) => index + 1,
			width: "80px",
		},
		{
			name: "Name",
			selector: (row) => row?.full_name,
		},
		{
			name: "Email Address",
			selector: (row) => row?.email ?? "N/A",
		},
		{
			name: "Phone No",
			selector: (row) => row?.phone,
		},
		{
			name: "Last Order Date",
			selector: (row) => (row?.last_order_date ? format(parse(row?.last_order_date?.split(" ")[0], "yyyy-MM-dd", new Date()), "PPP") : "N/A"),
		},
		{
			name: "Signup Date",
			selector: (row) => row?.created_at && format(parse(row?.created_at?.split("T")[0], "yyyy-MM-dd", new Date()), "PPP"),
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					<UserIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setSelectedCustomerDetails(row);
							setIsCustomerModalOpen(true);
						}}
					/>
				</div>
			),
			width: "50px",
			compact: true,
		},
	];

	const totalCount = data?.data?.total;
	const recentCustomers = data?.data?.data;

	return (
		<div className="">
			<div className="flex items-center justify-between my-[30px]">
				<h2 className="text-black text-[2rem] font-bold">Recent Customers ({renderNumber(totalCount)})</h2>
				<div className="flex items-center gap-3">
					<Popup
						trigger={<FilterDateTrigger name={"Filter"} isLoading={false} />}
						arrow={false}
						closeOnDocumentClick
						className="date"
						position={["bottom left"]}
						contentStyle={{
							width: "fit-content",
							padding: 0,
						}}
					>
						<FilterByDate />
					</Popup>
					<button onClick={() => exportRecentCustomerMetrics({ startDate, endDate })} className="flex items-center btn-primary">
						Export
					</button>
				</div>
			</div>

			<CustomDataTable columns={columns} data={recentCustomers} paginationTotalRows={totalCount} />

			<Modal isOpen={isCustomerModalOpen} setIsOpen={setIsCustomerModalOpen} title="Customer Details" size="large">
				<CustomerDetails customerDetails={selectedCustomerDetails} />
			</Modal>
		</div>
	);
};
export default NewCustomer;
