import { addDays, format, subDays } from "date-fns";
import { Formik } from "formik";
import React from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { useSearchParams } from "react-router-dom";
import { CalendarIcon, DownIcon } from "src/assets/svg";
import { Spinner } from "./Loader";

const filterOptions = [
	{ title: "Last 24 Hours", id: 3, diff: 1 },
	{ title: "Last Week", id: 4, diff: 7 },
	{ title: "Last 2 Weeks", id: 5, diff: 14 },
	{ title: "Last Month", id: 6, diff: 30 },
	{ title: "Last 2 Months", id: 7, diff: 60 },
];

const filterDateUrlKeys = ["endDate", "startDate"];

function FilterByDate() {
	const [searchParams, setSearchParams] = useSearchParams();

	const initialValues = {
		option: "0",
		range: [
			{
				startDate: null,
				endDate: null,
				key: "selection",
				color: "var(--primary-color)",
			},
		],
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values) => {
				const data = {
					startDate: !!values.range[0].startDate ? format(values.range[0].startDate, "yyyy-MM-dd") : null,
					endDate: !!values.range[0].endDate ? format(values.range[0].endDate, "yyyy-MM-dd") : null,
				};

				// remove keys that have empty strings
				for (const key in data) {
					if (!data[key]) {
						delete data[key];
					}
				}

				// remove all keys
				filterDateUrlKeys.forEach((key) => searchParams.delete(key));

				// save to url
				Object.entries(data).forEach((entry) => {
					const [key, value] = entry;
					searchParams.set(key, value);
				});
				setSearchParams(searchParams);
			}}
		>
			{({ values, handleChange, handleSubmit, setFieldValue, resetForm, submitForm }) => {
				return (
					<form className="z-10 p-6 bg-white rounded-lg" onSubmit={handleSubmit}>
						<h2 className="text-primary text-[1.8rem] font-medium mb-[3rem]">Filter Date Range</h2>
						<div className="flex gap-[50px] max-[455px]:flex-col">
							<div>
								{filterOptions.map((option, index) => (
									<div className="flex items-center gap-4 mb-4" key={index}>
										<input
											type="radio"
											name="option"
											value={option.id}
											id={`date-${option.id}`}
											onChange={(event) => {
												handleChange(event);
												setFieldValue("range", [
													{
														startDate: subDays(new Date(), option.diff),
														endDate: new Date(),
														key: "selection",
														color: "var(--primary-color)",
													},
												]);
											}}
											className="!m-0 border-2 border-gray-200 rounded-full appearance-none cursor-pointer w-7 h-7 checked:bg-primary checked:border-primary"
										/>
										<label
											key={option.title}
											className="relative text-lightText font-normal text-[1.4rem]  cursor-pointer m-0"
											htmlFor={`date-${option.id}`}
										>
											{option.title}
										</label>
									</div>
								))}
							</div>
							<div>
								<div className="flex text-[1.4rem] text-[#344054] font-medium">
									<div className="grow-1">From</div>
									<div className="grow-1">To</div>
								</div>
								<DateRange
									editableDateInputs={true}
									color="red"
									onChange={(item) => {
										setFieldValue("range", [item.selection]);
										setFieldValue("option", "custom");
									}}
									moveRangeOnFirstSelection={false}
									dateDisplayFormat="dd-MM-yyyy"
									ranges={values.range}
									maxDate={addDays(new Date(), 1)}
								/>
							</div>
						</div>
						<div className="flex gap-3">
							<button
								type="button"
								className="m-0 ml-auto btn-form-neutral px-[2.25rem] py-[0.75rem]"
								onClick={() => {
									resetForm();
									submitForm();
								}}
							>
								Clear
							</button>
							<button type="submit" className="m-0 btn-form-primary h-fit px-[2.25rem] py-[0.75rem]">
								Apply
							</button>
						</div>
					</form>
				);
			}}
		</Formik>
	);
}

export const FilterDateTrigger = React.forwardRef(({ name, isLoading, ...props }, ref) => {
	return (
		<button
			type="button"
			className={`flex items-center px-[13px] py-[10px] gap-3 w-fit rounded-md border-gray-300 border-[1px] cursor-pointer`}
			ref={ref}
			{...props}
		>
			{isLoading ? (
				<Spinner />
			) : (
				<>
					<CalendarIcon size={14} />
					<span className="flex items-center text-[1.4rem] font-normal border-light text-darkText">{name}</span>
					<DownIcon />
				</>
			)}
		</button>
	);
});

export default FilterByDate;
