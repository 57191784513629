import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { BsEye } from "react-icons/bs";
import { Link, useSearchParams } from "react-router-dom";
import { getOrders } from "src/api";
import { renderCurrency } from "src/assets/functions";
import { RackIcon, ReceiptIcon, UserIcon } from "src/assets/svg";
import { CustomDataTable, Modal } from "src/components";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { AddCustomer, OrderDetails, SetTrack } from "src/modals/orders";

const Ready = ({ tempArray }) => {
	const { user } = useProtectedRoutesContext();
	const [searchParams] = useSearchParams();

	const page = searchParams.get("page") ?? 1;
	const per_page = searchParams.get("per_page") ?? 50;

	const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
	const [isRackOpen, setIsRackOpen] = useState(false);

	const columns = [
		{
			name: "Customer",
			selector: (row) => row?.customer?.full_name,
			width: "230px",
			compact: true,
		},
		{
			name: "Order ID",
			selector: (row) => row?.serial_number,
			width: "80px",
			compact: true,
		},
		{
			name: "Placed",
			selector: (row) => format(parseISO(row?.created_at), "dd MMM yyyy"),
			width: "130px",
			compact: true,
		},
		{
			name: "Ready By",
			selector: (row) => (row?.dateTimeOut ? format(new Date(row?.dateTimeOut), "dd MMM yyyy") : "N/A"),
			width: "120px",
			compact: true,
		},
		{
			name: "Pieces",
			selector: (row) => row?.transactions?.length,
			width: "60px",
			compact: true,
		},
		{
			name: "Order",
			cell: (row) => (
				<div className="text-[1.2rem] font-medium leading-relaxed w-[95%] py-[7px] capitalize">
					{row?.transactions?.reduce(
						(string, { item }) => string.concat(item?.brand, " ", item?.description || "", " ", item?.product?.name, ", "),
						""
					)}
				</div>
			),
			wrap: true,
			minWidth: "200px",
			compact: true,
		},
		{
			name: "Status",
			selector: (row) => (!!row.is_paid ? "Paid" : "Not Paid"),
			width: "100px",
			compact: true,
		},
		{
			name: "Rack",
			selector: (row) => row?.serial_number.slice(-2),
			width: "80px",
			compact: true,
		},
		{
			name: "Amount Left",
			selector: (row) => renderCurrency(row?.bill - row?.paidAmount),
			width: "130px",
			compact: true,
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					<BsEye
						className="cursor-pointer stroke-primary text-primary"
						title="View Order Details"
						onClick={() => {
							setSelectedOrder(row);
							setIsOrderDetailsModalOpen(true);
						}}
					/>
					<Link to={`/receipt/${row.serial_number}`} target="_blank">
						<ReceiptIcon className="cursor-pointer stroke-primary text-primary" title="Print Order Receipt" />
					</Link>
					<RackIcon
						className="cursor-pointer stroke-primary text-primary"
						title="Assign Rack"
						onClick={() => {
							setSelectedCustomer(row);
							setIsRackOpen(true);
						}}
					/>
					<UserIcon
						className="cursor-pointer stroke-primary text-primary"
						title="View Customer Details"
						onClick={() => {
							setSelectedCustomer(row.customer);
							setIsCustomerModalOpen(true);
						}}
					/>
				</div>
			),
			width: "80px",
			compact: true,
		},
	];

	const location = user?.role === "admin" ? "" : user?.location_id;

	const { data, refetch } = useQuery({
		queryKey: ["orders", "ready", location, page, per_page],
		queryFn: () => getOrders({ status: "ready", location, page, per_page }),
		suspense: true,
	});

	return (
		<>
			<div>
				<div className="flex items-center justify-between my-[2rem]">
					<h1 className="text-black text-[2rem] font-bold">Ready</h1>
				</div>
				<CustomDataTable
					columns={columns}
					data={!!tempArray?.length ? tempArray : data?.data?.data}
					paginationTotalRows={data?.data?.total}
				/>
			</div>
			<Modal isOpen={isOrderDetailsModalOpen} setIsOpen={setIsOrderDetailsModalOpen} title="Order Details" size="large">
				<OrderDetails page="Ready" selectedOrder={selectedOrder} />
			</Modal>
			<Modal isOpen={isCustomerModalOpen} setIsOpen={setIsCustomerModalOpen} title="Edit Customer Details" size="large">
				<AddCustomer customer={selectedCustomer} setIsOpen={setIsCustomerModalOpen} refetch={refetch} />
			</Modal>
			<Modal isOpen={isRackOpen} setIsOpen={setIsRackOpen} title="Assign Rack" size="xsm">
				<SetTrack order={selectedCustomer} setIsOpen={setIsRackOpen} refetch={refetch} />
			</Modal>
		</>
	);
};

export default Ready;
