import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "./tabs.css";
import { CustomerOrders, CustomerPaidOrders, CustomerUnpaidOrders, Invoice } from "src/components/customers";
import { Link } from "react-router-dom";
import { renderCurrency } from "src/assets/functions";

const CustomerDetails = ({ customerDetails }) => {
	return (
		<div>
			<div className="flex items-center justify-between mt-[4rem] mb-[2rem]">
				<h2 className="text-darkText text-[2rem] font-semibold capitalize">{customerDetails?.full_name}</h2>
				<Link
					to={`/orders`}
					state={{
						id: customerDetails.id,
						name: customerDetails.full_name?.concat(" (", renderCurrency(customerDetails?.wallet), ")"),
						discount: customerDetails?.discount,
						default_payment: customerDetails?.default_payment,
					}}
					className="btn-primary"
				>
					Create Order
				</Link>
			</div>
			<Tabs className="modal-tabs">
				<TabList className="modal-tabs__tab-list">
					<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
						Orders
					</Tab>
					<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
						Paid
					</Tab>
					<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
						Unpaid
					</Tab>
					<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
						Invoice
					</Tab>
				</TabList>

				<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
					<CustomerOrders customerDetails={customerDetails} />
				</TabPanel>
				<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
					<CustomerPaidOrders customerDetails={customerDetails} />
				</TabPanel>
				<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
					<CustomerUnpaidOrders customerDetails={customerDetails} />
				</TabPanel>
				<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
					<Invoice customerDetails={customerDetails} />
				</TabPanel>
			</Tabs>
		</div>
	);
};

export default CustomerDetails;
