import LineChart from "src/LineChart";
import { CustomDataTable, FilterByDate, FilterDateTrigger } from "src/components";
import { getMetricsOrders, getMetricsOrdersChart } from "src/api";
import { useQueries } from "@tanstack/react-query";
import { renderCurrency } from "src/assets/functions";
import { format, parse, subDays } from "date-fns";
import { useSearchParams } from "react-router-dom";
import Popup from "reactjs-popup";

const OrderMetrics = () => {
	const [searchParams] = useSearchParams();

	const startDate = searchParams.get("startDate") || format(subDays(new Date(), 7), "yyyy-MM-dd");
	const endDate = searchParams.get("endDate") || format(new Date(), "yyyy-MM-dd");
	const page = searchParams.get("page") ?? 1;
	const per_page = searchParams.get("per_page") ?? 50;

	const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
	const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());

	const formattedStartDate = format(parsedStartDate, "dd MMM yyyy");
	const formattedEndDate = format(parsedEndDate, "dd MMM yyyy");

	const [{ data: rawOrderMetric, isLoading: isOrderMetricLoading }, { data: rawOrderMetricChart }] = useQueries({
		queries: [
			{
				queryKey: ["getMetricsOrders", startDate, endDate, page, per_page],
				queryFn: () => getMetricsOrders({ startDate, endDate, page, per_page }),
			},
			{
				queryKey: ["getMetricsOrdersChart", startDate, endDate],
				queryFn: () => getMetricsOrdersChart({ startDate, endDate }),
				suspense: true,
			},
		],
	});

	const columns = [
		{
			name: "Date",
			selector: (row) => row?.Date && format(parse(row?.Date?.split(" ")[0], "yyyy-MM-dd", new Date()), "PPP"),
		},
		{
			name: "Orders",
			selector: (row) => row?.Orders,
		},
		{
			name: "Sales",
			selector: (row) => renderCurrency(row?.Sales),
		},
	];

	const orderMetricData = rawOrderMetric?.data?.data;
	const orderMetricCount = rawOrderMetric?.data?.total;
	const orderMetricChart = rawOrderMetricChart?.data;

	return (
		<div className="px-[2.5rem]">
			<div className="float-right mt-[25px]">
				<Popup
					trigger={<FilterDateTrigger name={`${formattedStartDate} - ${formattedEndDate}`} />}
					arrow={false}
					closeOnDocumentClick
					className="date"
					position={["bottom left"]}
					contentStyle={{
						width: "fit-content",
						padding: 0,
					}}
				>
					<FilterByDate />
				</Popup>
			</div>
			<div style={{ height: 600 }}>
				<LineChart
					chartData={{
						labels: orderMetricChart?.map((data) => data.order_date),
						datasets: [
							{
								label: "Sales",
								data: orderMetricChart?.map((data) => data.total),
								backgroundColor: ["#0000A7"],
								borderColor: "#0000A7",
								borderWidth: 1.5,
							},
							{
								label: "Count",
								data: orderMetricChart?.map((data) => data.count),
								backgroundColor: ["#4BC0C0"],
								borderColor: "#4BC0C0",
								borderWidth: 1.5,
							},
						],
					}}
				/>
			</div>
			<div className="mt-[100px]">
				<h2 className="mb-[20px] mt-[50px] text-[#49515C] font-[700]">Orders</h2>
				<CustomDataTable
					columns={columns}
					data={orderMetricData}
					paginationTotalRows={orderMetricCount}
					progressPending={isOrderMetricLoading}
				/>
			</div>
		</div>
	);
};
export default OrderMetrics;
