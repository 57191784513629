import { CustomDataTable, FilterByDate, FilterDateTrigger } from "src/components";
import "chart.js/auto";
import { getAllDepartments, getCleaningMetrics } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { addDays, format, parse } from "date-fns";
import { useSearchParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { renderNumber } from "src/assets/functions";

const Cleaning = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const department = searchParams.get("department");
	const page = searchParams.get("page") ?? 1;
	const per_page = searchParams.get("per_page") ?? 50;
	const startDate = searchParams.get("startDate");
	const endDate = searchParams.get("endDate");
	const status = searchParams.get("status");

	const parsedStartDate = !!startDate ? parse(startDate, "yyyy-MM-dd", new Date()) : new Date();
	const parsedEndDate = !!endDate ? parse(endDate, "yyyy-MM-dd", new Date()) : addDays(parsedStartDate, 7);

	const formattedStartDate = format(parsedStartDate, "dd MMM yyyy");
	const formattedEndDate = format(parsedEndDate, "dd MMM yyyy");

	const { data: rawDepartments } = useQuery({
		queryKey: ["getAllDepartments"],
		queryFn: () => getAllDepartments(),
		suspense: true,
	});

	const departments = rawDepartments?.data;
	const currentDepartment = departments?.find((dep) => String(dep?.id) === department) ?? departments?.[0];

	const { data: rawMetrics, isFetching } = useQuery({
		queryKey: ["cleaningMetrics", department, page, per_page, startDate, endDate, status],
		queryFn: () =>
			getCleaningMetrics({
				department: currentDepartment?.id,
				page,
				per_page,
				startDate: startDate ?? format(parsedStartDate, "yyyy-MM-dd"),
				endDate: endDate ?? format(parsedEndDate, "yyyy-MM-dd"),
				status,
			}),
	});

	const total_count = rawMetrics?.data?.items?.total;
	const scan_in_only_count = rawMetrics?.data?.scan_in_only_count;
	const scanned_out = rawMetrics?.data?.scanned_out;
	const cleaningMetricsItems = rawMetrics?.data?.items?.data;

	const columns = [
		{
			name: "Tag ID",
			selector: (row) => row?.item?.tagId,
		},
		{
			name: "Department",
			selector: (row) => row?.departments?.name,
			style: { textTransform: "capitalize" },
		},
		{
			name: "Scan In Time",
			selector: (row) => (!row?.scan_in_time ? "-" : format(parse(row?.scan_in_time, "yyyy-MM-dd HH:mm:ss", new Date()), "PPP")),
		},
		{
			name: "Scan Out Time",
			selector: (row) => (!row?.scan_out_time ? "-" : format(parse(row?.scan_out_time, "yyyy-MM-dd HH:mm:ss", new Date()), "PPP")),
		},
		{
			name: "Sent to",
			selector: (row) => (!!row?.departments?.scan_in_out ? row?.sent_to_name : !!row?.scan_out_time && row?.sent_to_name),
		},
	];

	return (
		<>
			<div className="grid px-[2.5rem] mt-[30px]">
				<div className="flex flex-wrap items-center gap-3 mb-[20px] ml-auto">
					<select
						className="px-3 py-4 capitalize rounded-md"
						onChange={(event) => {
							searchParams.set("status", event.target.value);
							setSearchParams(searchParams);
						}}
						value={status}
					>
						<option value="">All</option>
						<option value="in">Still in department</option>
						<option value="out">Out of department</option>
					</select>
					<select
						className="px-3 py-4 capitalize rounded-md"
						onChange={(event) => {
							searchParams.set("department", event.target.value);
							setSearchParams(searchParams);
						}}
						value={department}
					>
						{departments?.map((department) => (
							<option value={department.id} key={department.id}>
								{department?.name}
							</option>
						))}
					</select>
					<Popup
						trigger={
							<FilterDateTrigger name={searchParams.has("startDate") ? `${formattedStartDate} - ${formattedEndDate}` : "Filter"} />
						}
						arrow={false}
						closeOnDocumentClick
						className="date"
						position={["bottom left"]}
						contentStyle={{
							width: "fit-content",
							padding: 0,
						}}
					>
						<FilterByDate />
					</Popup>
				</div>
				<div className="grid grid-cols-[repeat(auto-fit,_minmax(220px,_1fr))] border border-gray-300 mb-12 divide-x divide-gray-300 rounded-lg">
					<div className="px-4 py-5 border border-gray-300">
						<div className="flex justify-between">
							<p className="text-[1.4rem] text-darkText font-medium">Total</p>
						</div>
						<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(total_count ?? 0)}</p>
					</div>
					<div className="px-4 py-5 border border-gray-300">
						<div className="flex justify-between">
							<p className="text-[1.4rem] text-darkText font-medium">Number out of department</p>
						</div>
						<p className="text-[2rem] leading-[3rem] font-bold">
							{!!currentDepartment?.scan_in_out ? renderNumber(scanned_out ?? 0) : renderNumber(scan_in_only_count ?? 0)}
						</p>
					</div>
					<div className="px-4 py-5 border border-gray-300">
						<div className="flex justify-between">
							<p className="text-[1.4rem] text-darkText font-medium">Number left in department</p>
						</div>
						<p className="text-[2rem] leading-[3rem] font-bold">
							{!!currentDepartment?.scan_in_out ? renderNumber(scan_in_only_count ?? 0) : 0}
						</p>
					</div>
				</div>
				<CustomDataTable progressPending={isFetching} columns={columns} paginationTotalRows={total_count} data={cleaningMetricsItems} />
			</div>
		</>
	);
};
export default Cleaning;
