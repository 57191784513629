import { useMutation, useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { createInvoice, getCustomerOrder } from "src/api";
import { renderCurrency, renderSuccessMessage } from "src/assets/functions";
import { DownIcon } from "src/assets/svg";
import { CustomDataTable, CustomPopup } from "src/components";

const CustomerOrders = ({ customerDetails }) => {
	const [text, setText] = useState("");
	const [selectedRows, setSelectedRows] = useState([]);

	const handleChange = ({ selectedRows }) => setSelectedRows(selectedRows);

	const { data } = useQuery({
		queryKey: ["getCustomerOrder", customerDetails?.id, text],
		queryFn: () => getCustomerOrder(customerDetails?.id, text),
		suspense: true,
	});

	const orders = data.data;
	const allPaid = orders.allPaid;
	const ordering = orders.order;

	const rowDisabledCriteria = (row) => allPaid?.some((order) => order.id === row?.id);

	const columns = [
		{
			name: "ID",
			selector: (row) => row?.serial_number,
			width: "90px",
			compact: true,
		},
		{
			name: "Order Date",
			selector: (row) => row?.dateTimeIn && format(parseISO(row?.dateTimeIn), "dd MMM yyyy"),
			compact: true,
		},
		{
			name: "Ready By",
			selector: (row) => (row?.dateTimeOut ? format(parseISO(row?.dateTimeOut), "dd MMM yyyy HH:mm") : row?.dateTimeOut),
			compact: true,
		},
		{
			name: "Status",
			selector: (row) => row?.status,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Item No",
			selector: (row) => row?.itemsCount,
			width: "80px",
			compact: true,
		},
		{
			name: "Rack",
			selector: (row) => (!!row.store_rack ? row.store_rack : "N/A"),
			width: "80px",
			compact: true,
		},
		{
			name: "Total",
			selector: (row) => renderCurrency(row?.bill),
			compact: true,
		},
	];
	const deliveredColumns = [
		{
			name: "ID",
			selector: (row) => row?.serial_number,
			width: "90px",
			compact: true,
		},
		{
			name: "Order Date",
			selector: (row) => row?.dateTimeIn && format(parseISO(row?.dateTimeIn), "dd MMM yyyy"),
			compact: true,
		},
		{
			name: "Collected on",
			selector: (row) => (row?.dateCollected ? format(new Date(row?.dateCollected), "dd MMM yyyy") : "N/A"),
			compact: true,
		},
		{
			name: "Status",
			selector: (row) => row?.status,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Item No",
			selector: (row) => row?.itemsCount,
			width: "80px",
			compact: true,
		},
		{
			name: "Rack",
			selector: (row) => (!!row.store_rack ? row.store_rack : "N/A"),
			width: "80px",
			compact: true,
		},
		{
			name: "Total",
			selector: (row) => renderCurrency(row?.bill),
			compact: true,
		},
	];
	const conditionalRowStyles = [
		{
			when: (row) => row.status === "deleted",
			style: {
				textDecoration: "line-through",
			},
		},
	];

	const { mutate, isLoading } = useMutation(createInvoice, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => setSelectedRows([])),
	});

	return (
		<div>
			<div className="grid grid-cols-4 mb-8 border divide-x rounded-lg border-lightGray divide-lightGray">
				<div className="px-4 py-5">
					<p className="text-[1.4rem] text-darkText font-medium">Orders</p>
					<p className="text-[2rem] leading-[3rem] font-bold">{orders.result}</p>
				</div>
				<div className="px-4 py-5">
					<p className="text-[1.4rem] text-darkText font-medium">Sales</p>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(orders.totalAmount)}</p>
				</div>
				<div className="px-4 py-5">
					<p className="text-[1.4rem] text-darkText font-medium">Paid</p>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(orders.paidAmount)}</p>
				</div>
				<div className="px-4 py-5">
					<p className="text-[1.4rem] text-darkText font-medium">Unpaid</p>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(orders.unpaidAmount)}</p>
				</div>
			</div>
			<div className="flex items-center justify-between mb-8">
				<p className="text-[1.6rem] font-semibold text-darkText">All Orders</p>
				<CustomPopup
					trigger={
						<button
							type="button"
							className="capitalize flex min-w-[90px] items-center justify-center px-[1.5rem] py-[0.65rem] rounded-md border-lightText border cursor-pointer gap-2 text-[1.4rem] text-darkText"
						>
							{text || "All"} <DownIcon />
						</button>
					}
					arrow={false}
					closeOnDocumentClick
					position={["bottom right"]}
					width={"140px"}
					nested
					childrenArray={[
						{
							text: <div className="flex items-center gap-3">All</div>,
							onClick: () => {
								setText("");
							},
						},
						{
							text: <div className="flex items-center gap-3">Processing</div>,
							onClick: () => {
								setText("processing");
							},
						},
						{
							text: <div className="flex items-center gap-3">Ready</div>,
							onClick: () => {
								setText("ready");
							},
						},
						{
							text: <div className="flex items-center gap-3">Completed</div>,
							onClick: () => {
								setText("completed");
							},
						},
						{
							text: <div className="flex items-center gap-3">Delivered</div>,
							onClick: () => {
								setText("delivered");
							},
						},
						{
							text: <div className="flex items-center gap-3">Deleted</div>,
							onClick: () => {
								setText("deleted");
							},
						},
					]}
				/>
			</div>
			{!!selectedRows?.length && (
				<div className="flex">
					<button
						className="mb-4 ml-auto btn-primary"
						disabled={isLoading}
						onClick={() =>
							mutate({
								orderID: selectedRows.reduce((string, order) => string.concat(order.uuid, ","), "").slice(0, -1),
								total: selectedRows.reduce((sum, order) => sum + parseFloat(order.bill || 0), 0),
								isPaid: false,
							})
						}
					>
						Create Invoice
					</button>
				</div>
			)}

			<CustomDataTable
				columns={text === "delivered" ? deliveredColumns : columns}
				data={ordering}
				selectableRows
				onSelectedRowsChange={handleChange}
				selectableRowDisabled={rowDisabledCriteria}
				conditionalRowStyles={conditionalRowStyles}
				paginationServer={false}
				paginationDefaultPage={1}
				paginationPerPage={50}
				onChangePage={() => {}}
				onChangeRowsPerPage={() => {}}
			/>
		</div>
	);
};

export default CustomerOrders;
