import React from "react";
import ordersIcon from "src/assets/images/orders.png";
import valueIcon from "src/assets/images/value.png";
import unpaidIcon from "src/assets/images/unpaid.png";
import { useQuery } from "@tanstack/react-query";
import { getAllLocation, getDashboardAnalytics, getFactoryNumbers, getMetricsOverview } from "src/api";
import { renderCurrency, renderNumber } from "src/assets/functions";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FilterByDate, FilterDateTrigger, Loader } from "src/components";
import Popup from "reactjs-popup";
import { format, parse } from "date-fns";
import { CreditIcon, DiscountIcon, MetricsIcon } from "src/assets/svg";

const Dashboard = () => {
	const { user } = useProtectedRoutesContext();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const location = searchParams.has("location") ? searchParams.get("location") : user?.role === "admin" ? "" : user?.location_id;

	const startDate = searchParams.get("startDate") ?? "2023-01-01";
	const endDate = searchParams.get("endDate") ?? "3000-12-31";

	const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
	const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());

	const formattedStartDate = format(parsedStartDate, "dd MMM yyyy");
	const formattedEndDate = format(parsedEndDate, "dd MMM yyyy");

	const { data, isLoading: isLoading1 } = useQuery({
		queryKey: ["getDashboardAnalytics", location, startDate, endDate],
		queryFn: () => getDashboardAnalytics({ location, start_date: startDate, end_date: endDate }),
		onError: () => navigate("/orders"),
	});
	const { data: metrics, isLoading: isLoading2 } = useQuery({
		queryKey: ["getMetricsOverview", startDate, endDate, location],
		queryFn: () => getMetricsOverview({ start_date: startDate, end_date: endDate, store_id: location }),
		onError: () => navigate("/orders"),
	});
	const { data: factory, isFetching: isLoading4 } = useQuery({
		queryKey: ["getFactoryNumbers", startDate, endDate],
		queryFn: () => getFactoryNumbers({ start_date: startDate, end_date: endDate }),
		onError: () => navigate("/orders"),
	});

	const { data: locations, isFetching: isLoading3 } = useQuery({
		queryKey: ["getAllLocation"],
		queryFn: () => getAllLocation(),
		enabled: user?.role === "admin",
	});

	const metrics1 = metrics?.data?.order;
	const pendingMetrics = metrics?.data?.pendingOrders;
	const factoryNumbers = factory?.data;

	if (isLoading1 || isLoading2 || isLoading3 || isLoading4) return <Loader spin />;

	return (
		<div>
			<div className="flex justify-between">
				<h2 className="my-[30px] text-black text-[2rem] font-bold">Dashboard</h2>
				<div className="flex items-center gap-4">
					{user?.role === "admin" && (
						<div className="my-[30px]">
							<select
								className="px-3 py-4 capitalize rounded-md"
								onChange={(event) => {
									searchParams.set("location", event.target.value);
									setSearchParams(searchParams);
								}}
								value={location}
							>
								<option value="">All Stores</option>
								{locations?.data?.map((location) => (
									<option value={location.id} key={location.id}>
										{location?.locationName}
									</option>
								))}
							</select>
						</div>
					)}
					<Popup
						trigger={
							<FilterDateTrigger name={searchParams.has("startDate") ? `${formattedStartDate} - ${formattedEndDate}` : "Filter"} />
						}
						arrow={false}
						closeOnDocumentClick
						className="date"
						position={["bottom left"]}
						contentStyle={{
							width: "fit-content",
							padding: 0,
						}}
					>
						<FilterByDate />
					</Popup>
				</div>
			</div>
			<div className="grid grid-cols-[repeat(auto-fit,_minmax(220px,_1fr))] border border-gray-300 mb-8 divide-x divide-gray-300 rounded-lg">
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Orders</p>
						<img src={ordersIcon} alt="" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(metrics1?.order_count ?? 0)}</p>
				</div>
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Pieces</p>
						<img src={ordersIcon} alt="" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(data?.data?.totalPieces ?? 0)}</p>
				</div>
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Total Amount</p>
						<img src={unpaidIcon} alt="" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(data?.data?.totalAmount ?? 0)}</p>
				</div>
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Paid</p>
						<img src={unpaidIcon} alt="" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(data?.data?.paidAmount ?? 0)}</p>
				</div>
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Unpaid</p>
						<img src={unpaidIcon} alt="" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(data?.data?.unpaidAmount ?? 0)}</p>
				</div>
			</div>
			<div className="grid grid-cols-[repeat(auto-fit,_minmax(220px,_1fr))] border border-gray-300 mb-8 divide-x divide-gray-300 rounded-lg">
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Discounts Used</p>
						<DiscountIcon className="w-[24px] h-[24px] stroke-primary" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(metrics1?.discount ?? 0)}</p>
				</div>
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Credit Used</p>
						<CreditIcon className="w-[24px] h-[24px] fill-primary" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(metrics1?.value ?? 0)}</p>
				</div>
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Orders Cleaned</p>
						<img src={valueIcon} alt="" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(metrics1?.orders_cleaned ?? 0)}</p>
				</div>
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Pieces Cleaned</p>
						<img src={valueIcon} alt="" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(metrics1?.pieces_cleaned ?? 0)}</p>
				</div>
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Revenue</p>
						<MetricsIcon className="w-[24px] h-[24px] stroke-primary text-primary" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(metrics1?.revenue ?? 0)}</p>
				</div>
			</div>
			<div className="grid grid-cols-[repeat(auto-fit,_minmax(220px,_1fr))] border border-gray-300 mb-8 divide-x divide-gray-300 rounded-lg">
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">All Due</p>
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(pendingMetrics?.orders ?? 0)}</p>
				</div>
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Completed</p>
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(pendingMetrics?.orders_ready ?? 0)}</p>
				</div>
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Not Completed</p>
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(pendingMetrics?.orders_not_ready ?? 0)}</p>
				</div>
				<div className="px-4 py-5 border border-gray-300">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Percentage completed (%)</p>
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">
						{((pendingMetrics?.orders_ready / pendingMetrics?.orders) * 100).toFixed(2)}%
					</p>
				</div>
			</div>
			<div className="max-w-[1000px] border-2 rounded-md border-gray-300">
				<div className="grid grid-cols-3 gap-4 px-6 py-4 mb-2 font-bold border-b border-gray-300">
					<div>Department Name</div>
					<div>Items Scanned In</div>
					<div>Items Scanned Out</div>
				</div>
				{factoryNumbers.map((stat) => (
					<div key={stat.department_id} className="grid grid-cols-3 gap-4 px-6 py-4 border-b border-gray-200">
						<div className="font-medium capitalize">{stat.department_name}</div>
						<div className="text-green-600">{renderNumber(stat.scanned_in_count)}</div>
						<div className="text-red-600">{renderNumber(stat.scanned_out_count)}</div>
					</div>
				))}
			</div>
		</div>
	);
};
export default Dashboard;
