import React from "react";
import LineGraph from "../LineGraph";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getMetricsPeriod } from "src/api";
import { options, createDataset, prepareChartData } from "src/assets/constants";
import { ctrlMetricNumber, ctrlPercentageMetric } from "src/assets/functions";

const OrderGraph = ({ metricPeriod }) => {
	const [searchParams] = useSearchParams();

	const startDate = searchParams.get("startDate") || "2023-08-07";
	const endDate = searchParams.get("endDate") || "2023-08-14";

	const period = metricPeriod;

	const { data } = useQuery({
		queryKey: ["metricsPeriod", startDate, endDate, period],
		queryFn: () => getMetricsPeriod({ startDate, endDate, period }),
		suspense: true,
	});

	const orderMetricsData = data?.data?.orders;

	const orderPeriod1Data = prepareChartData(orderMetricsData?.["order-period-1"]?.order);
	const orderPeriod2Data = prepareChartData(orderMetricsData?.["order-period-2"]?.order);

	const dataset1 = createDataset(orderPeriod1Data, "Total", "#999");
	const dataset2 = createDataset(orderPeriod2Data, "Total", "#0000A7");

	const chartData = {
		datasets: [dataset2, dataset1],
	};

	const [metricPercentage, percentageColor, percentageBg] = ctrlPercentageMetric(orderMetricsData?.percentageDifference);

	const [orderPrice1, orderPrice2] = ctrlMetricNumber(
		orderMetricsData?.["order-period-1"]?.["order-period1-total"],
		orderMetricsData?.["order-period-2"]?.["order-period2-total"],
		"",
		0
	);

	return (
		<div className="py-10 bg-white border graph-box px-14 rounded-xl">
			<div className="flex items-center gap-5 mb-12 title">
				<h2 className="text-3xl font-semibold">Orders</h2>
				<span
					style={{
						color: percentageColor,
						backgroundColor: percentageBg,
					}}
					className={`text-xl transition-all py-1 px-3 rounded-3xl !important`}
				>{`${metricPercentage}%`}</span>
			</div>
			<div className="flex justify-between prices mb-7">
				<div className="p1 text-primary">{`${orderPrice2}`}</div>
				<div className="p2">{`${orderPrice1}`}</div>
			</div>
			<div className="graph w-[430]">
				<LineGraph data={chartData} options={options} />
			</div>
		</div>
	);
};

export default OrderGraph;
