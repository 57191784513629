import React, { Suspense } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Loader } from "src/components";
import Analytics from "./Analytics";
import Overview from "./Overview";
import Revenue from "./Revenue";
import Unpaid from "./Unpaid";
import OrderMetrics from "./OrderMetrics";
import CustomerTab from "./CustomerTab";
import Cleaning from "./Cleaning";
import Products from "./Products";

const Metrics = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	return (
		<>
			<Tabs className="modal-tabs" defaultIndex={parseInt(searchParams.get("tab")) || 0} onSelect={(index) => setSearchParams({ tab: index })}>
				<TabList className="bg-white border-b border-b-lightGray flex items-center justify-between py-[1.45rem] w-full">
					<div className="tabs">
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Analytics
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Due Orders
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Revenue
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Unpaid
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Orders
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Customers
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Cleaning
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Products
						</Tab>
					</div>
				</TabList>

				<Suspense fallback={<Loader spin />}>
					<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
						<Analytics />
					</TabPanel>
					<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
						<Overview />
					</TabPanel>
					<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
						<Revenue />
					</TabPanel>
					<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
						<Unpaid />
					</TabPanel>
					<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
						<OrderMetrics />
					</TabPanel>
					<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
						<CustomerTab />
					</TabPanel>
					<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
						<Cleaning />
					</TabPanel>
					<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
						<Products />
					</TabPanel>
				</Suspense>
			</Tabs>
		</>
	);
};

export default Metrics;
