import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Suspense } from "react";
import { Loader } from "src/components";
import CustomerOrder from "./CustomerOrder";
import NewCustomer from "./NewCustomer";
// import CustomerWithNoRecentOrder from "./CustomerWithNoRecentOrder";

const CustomerTab = () => {
	return (
		<>
			<Tabs className="modal-tabs">
				<TabList className="bg-white border-b border-b-lightGray flex items-center justify-between py-[1.45rem] w-full px-[2.5rem]">
					<div className="tabs">
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Orders
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							New Customers
						</Tab>
						{/* <Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Customers with No Recent Orders
						</Tab> */}
					</div>
				</TabList>

				<Suspense fallback={<Loader spin />}>
					<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
						<CustomerOrder />
					</TabPanel>
					<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected px-[2.5rem]">
						<NewCustomer />
					</TabPanel>
					{/* <TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected px-[2.5rem]">
						<CustomerWithNoRecentOrder customerOrders={data} />
					</TabPanel> */}
				</Suspense>
			</Tabs>
		</>
	);
};
export default CustomerTab;
