import React, { Suspense, useState } from "react";
import Popup from "reactjs-popup";
import { DownIcon } from "src/assets/svg";
import { CustomPopup, FilterByDate, FilterDateTrigger, Loader } from "src/components";
import SalesGraph from "./Graphs/SalesGraph";
import RevenueGraph from "./Graphs/RevenueGraph";
import OrderGraph from "./Graphs/OrderGraph";
import { metricsPeriod } from "src/assets/constants";
import { useSearchParams } from "react-router-dom";
import { addDays, format, parse } from "date-fns";
import AverageSpendGraph from "./Graphs/AverageSpendGraph";
import PopularDaysGraph from "./Graphs/PopularDaysGraph";
import NewCustomerGraph from "./Graphs/NewCustomerGraph";

import TopCustomer from "./Graphs/TopCustomer";
import TopSections from "./Graphs/TopSections";
import TopItems from "./Graphs/TopItems";
import LineChart from "src/LineChart";
import { useQuery } from "@tanstack/react-query";
import { OrdersPerHour, getAllLocation } from "src/api";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";

const Analytics = () => {
	const { user } = useProtectedRoutesContext();
	const [text, setText] = useState(metricsPeriod[0].periodText);
	const [param, setParam] = useState(metricsPeriod[0].param);

	const [dailyText, setDailyText] = useState("daily");

	const [searchParams, setSearchParams] = useSearchParams();

	const startDate = searchParams.get("startDate");
	const endDate = searchParams.get("endDate");

	const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
	const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());

	const formattedStartDate = format(startDate ? parsedStartDate : new Date(), "dd MMM yyyy");
	const formattedEndDate = format(endDate ? parsedEndDate : addDays(new Date(), 10), "dd MMM yyyy");

	const { data: locations } = useQuery({
		queryKey: ["getAllLocation"],
		queryFn: () => getAllLocation(),
		enabled: user?.role === "admin",
		suspense: true,
	});

	return (
		<div className="flex flex-col px-20 py-20">
			<select
				className="px-3 py-4 ml-auto capitalize rounded-md"
				onChange={(event) => {
					searchParams.set("location", event.target.value);
					setSearchParams(searchParams);
				}}
			>
				<option value="">All Stores</option>
				{locations?.data?.map((location) => (
					<option value={location.id} key={location.id}>
						{location?.locationName}
					</option>
				))}
			</select>
			<div style={{ height: 600 }} className="mb-[6rem]">
				<Suspense fallback={<Loader spin />}>
					<RenderChart />
				</Suspense>
			</div>
			<div className="flex justify-between mb-20">
				<CustomPopup
					trigger={
						<button
							type="button"
							className="flex min-w-[90px] items-center justify-center px-[1.5rem] py-[0.65rem] rounded-md border-lightText border cursor-pointer gap-2 text-[1.4rem] text-darkText capitalize"
						>
							{dailyText} <DownIcon />
						</button>
					}
					arrow={false}
					closeOnDocumentClick
					position={["bottom left"]}
					width={"fit-content"}
					nested
					childrenArray={[
						{
							text: <div className="flex items-center gap-3">{dailyText}</div>,
							onClick: () => {
								setDailyText("Daily");
							},
						},
					]}
				/>
				<div className="flex gap-20 right">
					<Popup
						trigger={<FilterDateTrigger name={`${formattedStartDate} - ${formattedEndDate}`} />}
						arrow={false}
						closeOnDocumentClick
						className="date"
						position={["bottom left"]}
						contentStyle={{
							width: "fit-content",
							padding: 0,
						}}
					>
						<FilterByDate />
					</Popup>
					<div className="flex items-center gap-10">
						<h1 className="text-[#47505B]">Compare to</h1>
						<CustomPopup
							trigger={
								<button
									type="button"
									className="flex min-w-[90px] items-center justify-center px-[1.5rem] py-[0.65rem] rounded-md border-lightText border cursor-pointer gap-2 text-[1.4rem] text-darkText capitalize"
								>
									{text} <DownIcon />
								</button>
							}
							arrow={false}
							closeOnDocumentClick
							position={["bottom top"]}
							width={"fit-content"}
							nested
							childrenArray={metricsPeriod?.map((period) => ({
								text: <div className="flex items-center gap-3">{period?.periodText}</div>,
								onClick: () => {
									setText(period?.periodText);
									setParam(period?.param);
								},
							}))}
						/>
					</div>
				</div>
			</div>
			<Suspense fallback={<Loader spin />}>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 mb-[6rem]">
					<div>
						<SalesGraph metricPeriod={param} />
					</div>
					<div>
						<RevenueGraph metricPeriod={param} />
					</div>
					<div>
						<OrderGraph metricPeriod={param} />
					</div>
					<div>
						<AverageSpendGraph metricPeriod={param} />
					</div>
					<div>
						<PopularDaysGraph metricPeriod={param} />
					</div>
					<div>
						<NewCustomerGraph metricPeriod={param} />
					</div>
					<div>
						<TopCustomer metricPeriod={param} />
					</div>
					<div>
						<TopSections metricPeriod={param} />
					</div>
					<div>
						<TopItems metricPeriod={param} />
					</div>
				</div>
			</Suspense>
		</div>
	);
};

const RenderChart = () => {
	const { user } = useProtectedRoutesContext();

	const [searchParams] = useSearchParams();

	const location = searchParams.has("location") ? searchParams.get("location") : user?.location_id;

	const { data: info } = useQuery({
		queryKey: ["OrdersPerHour", location],
		queryFn: () => OrdersPerHour({ location }),
		suspense: true,
	});

	return (
		<LineChart
			chartData={{
				labels: info?.map((data) => data?.hour.split(" ")[1]),
				datasets: [
					{
						label: "Orders Per Hour",
						data: info?.map((data) => data?.order_count),
						backgroundColor: ["#0000A7"],
						borderColor: "#0000A7",
						borderWidth: 1.5,
					},
				],
			}}
		/>
	);
};

export default Analytics;
