import { useQuery } from "@tanstack/react-query";
import LineChart from "src/LineChart";
import { getRevenue } from "src/api";
import { CustomDataTable, FilterByDate, FilterDateTrigger } from "src/components";
import { renderCurrency } from "src/assets/functions";
import Popup from "reactjs-popup";
import { useSearchParams } from "react-router-dom";
import { format, parse, subDays } from "date-fns";

const Revenue = () => {
	const [searchParams] = useSearchParams();

	const startDate = searchParams.get("startDate") || format(subDays(new Date(), 7), "yyyy-MM-dd");
	const endDate = searchParams.get("endDate") || format(new Date(), "yyyy-MM-dd");

	const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
	const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());

	const formattedStartDate = format(parsedStartDate, "dd MMM yyyy");
	const formattedEndDate = format(parsedEndDate, "dd MMM yyyy");

	const { data: revenue } = useQuery({
		queryKey: ["getRevenue", startDate, endDate],
		queryFn: () => getRevenue({ startDate, endDate }),
		suspense: true,
	});
	const columns = [
		{
			name: "Date",
			selector: (row) => row?.Date && format(parse(row?.Date?.split(" ")[0], "yyyy-MM-dd", new Date()), "PPP"),
			width: "200px",
		},
		{
			name: "Revenue",
			selector: (row) => row?.revenue,
		},
		{
			name: "Paid by Cash",
			selector: (row) => row?.cash_revenue,
		},
		{
			name: "Paid by Card",
			selector: (row) => row?.card_revenue,
		},
		{
			name: "Paid from Wallet",
			selector: (row) => row?.wallet_revenue,
		},
		{
			name: "Paid by Cheque",
			selector: (row) => row?.cheque_revenue,
		},
		{
			name: "Paid by Bank",
			selector: (row) => row?.bank_revenue,
		},
	];

	const totalRevenue = revenue?.data?.revenue?.data?.reduce((sum, item) => sum + parseFloat(item.revenue), 0);
	const totalCash = revenue?.data?.revenue?.data?.reduce((sum, item) => sum + parseFloat(item.cash_revenue), 0);
	const totalCard = revenue?.data?.revenue?.data?.reduce((sum, item) => sum + parseFloat(item.card_revenue), 0);
	const totalWallet = revenue?.data?.revenue?.data?.reduce((sum, item) => sum + parseFloat(item.wallet_revenue), 0);
	const totalCheque = revenue?.data?.revenue?.data?.reduce((sum, item) => sum + parseFloat(item.cheque_revenue), 0);
	const totalBank = revenue?.data?.revenue?.data?.reduce((sum, item) => sum + parseFloat(item.bank_revenue), 0);

	const footer = {
		date: "Total",
		revenue: renderCurrency(totalRevenue),
		cash_revenue: renderCurrency(totalCash),
		card_revenue: renderCurrency(totalCard),
		wallet_revenue: renderCurrency(totalWallet),
		cheque_revenue: renderCurrency(totalCheque),
		bank_revenue: renderCurrency(totalBank),
	};

	return (
		<>
			<div className="px-[2.5rem] mt-[40px] ">
				<div className="float-right">
					<Popup
						trigger={<FilterDateTrigger name={`${formattedStartDate} - ${formattedEndDate}`} />}
						arrow={false}
						closeOnDocumentClick
						className="date"
						position={["bottom left"]}
						contentStyle={{
							width: "fit-content",
							padding: 0,
						}}
					>
						<FilterByDate />
					</Popup>
				</div>

				<div style={{ height: 500 }}>
					<LineChart
						chartData={{
							labels: revenue.data.revenueChart?.map((data) => data.order_date),
							datasets: [
								{
									label: "Revenue",
									data: revenue.data.revenueChart?.map((data) => data.total),
									backgroundColor: ["#0000A7"],
									borderColor: "#0000A7",
									borderWidth: 1.5,
								},
							],
						}}
					/>
				</div>
				<div className="mt-[100px]">
					<CustomDataTable columns={columns} data={revenue.data?.revenue?.data} footer={footer} />
				</div>
			</div>
		</>
	);
};
export default Revenue;
